<template>
    <div class="stakewait">


     <div class="coinInfomation" >
          <div class="coinImg"><img :src="bull2" /></div>
          <div class="coinInfo">{{$t("menu.EvDao")}}</div>
          <img :src="swap_arrow" class="swaparrow" />
    </div>

      <div class="infoContent">
        <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
        <div class="infoText">{{ $t("Governance.tips") }}</div>
      </div>


      <Divider style="background: rgba(142, 200, 243, 0.5)"/>

      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("Governance.YourDeposit")}}:</div>
        <div class="ownMoney">
         <div class="m">$ {{formattedDaiOwnMoney2}}</div>
         <img class="daimi" :src="swap_usdt"/>
        </div>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("govern.mappinged")}}:</div>
        <div class="ownMoney">
         <div class="m">$ {{mapTokenUsersdata.mapAmount | fildecimals}}</div>
         <img class="daimi" :src="swap_usdt"/>
        </div>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("govern.putmoney")}}:</div>
        <div class="ownMoney">
         <div class="m">$ {{mapTokenUsersdata.extractedMapAmount | fildecimals}}</div>
         <img class="daimi" :src="swap_usdt"/>
        </div>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("govern.putbfb")}}:</div>
        <div class="ownMoney">
         <div class="m">{{percentage | fildecimals}} %</div>
         <img class="daimi" :src="burnbl"/>
        </div>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("Governance.MappingStatus")}}:</div>
        <div class="ownMoney">
         <div class="m">{{$t("Governance.InProgress")}}</div>
        </div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.5)"/>
      <div style="text-align: center;font-size: 18px;" v-if="mapTokenUsersdata.mapNumber>0">
        {{$t("Governance.Congratulations")}}<span style="color: #05cb04;">{{mapTokenUsersdata.mapNumber}}</span>{{$t("Governance.QuotaReleasePackage")}}
      </div>
      <div class="ownwalletInfo" style="margin-top: 15px;">
        <div class="ownTips">{{$t("Governance.ProvideMoney")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">

        </div>
      </div>

      <div class="inputInfo">
        <input class="inputStyle" v-model="value1" />

      </div>
      <div class="ownwalletInfo" style="margin-top: 15px;">
        <div class="ownTips">{{$t("Governance.Provide")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">

        </div>
      </div>
      <div class="inputInfo">
        <input class="inputStyle" v-model="tokenvalue" />

      </div>



      <div class="StakeAuthor">
        <!-- <div class="Author">
          <button class="StakeBtn ripple">
            {{ $t("stake.Appoove") }}
          </button>
           <button class="StakeBtn" v-if="typeApprove == 1">
                {{ $t("stake.Appooveing") }}
            </button>
        </div> -->

        <div class="Stakeing" style="width: 100%;">
          <button class="StakeBtn ripple" style="width: 100%;" v-if="mapTokenUsersdata.extractedMapAmount>0" @click="recoveryMapUsers()">
            {{$t("Governance.StartMapping")}}
          </button>
          <button class="StakeBtn ripple" style="width: 100%;" disabled v-else>
            {{$t("Governance.StartMapping")}}
          </button>
        </div>
      </div>
      <div class="endTime" style="text-align: center;margin-top: 15px;font-size: 16px;" v-if="finishshow==false">
        {{$t("Governance.lockTips")}}:{{countdown}} {{$t("Governance.Automatically unlocked after")}}
      </div>


      <div class="infoContent">
        <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
        <div class="infoText"> * {{ $t("Governance.locktime") }}</div>
      </div>


    <div>
      <video class="btnvideo" src="https://dm.bull-developer.com/video/162.mp4" autoplay loop muted playsinline>
      {{$t("network.video")}}
      </video>
    </div>
      <!--loading start-->
      <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
    </div>
  </template>
  <style scoped src="@/assets/css/EvFalocnV4.css"></style>
  <style lang="less" scoped>
  .btnvideo{
  width:100%;
}
  .infoContent {
    margin-top: 20px;
  display: flex;

  align-items: left;
  justify-content: left;
  gap: 5px;
  text-align: left;
  font-size: 14px;
  height: 100%;
  margin-bottom: 20px;
  .infoImg{
    img{
      position: relative;
      top: 3px;
      width: 16px ;
      height: 16px ;
    }
  }
  .infoText{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
  }
}
  .time_c {
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
  }
img .dailogo2{
  width: 30px !important;
  height: 30px !important;
}
  .nav {
    width: 100%;
    border-radius: 6px;
    margin: 0 auto;
  }

  .nav ul {
    width: 330px;
    margin: 0 auto;
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  .nav ul li {
    flex: 1;
  }

  .nav ul li .nav_c {
    color: #808383;
    font-family: "Poppins", sans-serif;
    padding: 8px;
    border-radius: 0px;
    background: #120245;
    border: 2px solid #05080b;
    display: block;
    text-align: center;
  }

  .nav ul li .nav_c_type {
    color: aqua;
    font-family: "Poppins", sans-serif;
    padding: 8px;
    font-weight: 800;
    border-radius: 5px;
    background: linear-gradient(to bottom, #5e0463, rgba(44, 144, 232, 0.603));
    color: white;
    border: 2px solid #05080b;
    display: block;
  }

  .ownwalletInfo {
  display: flex;
  margin-bottom: 5px;
}

.ownwalletInfo .ownTips {
  flex: 1;
  font-size: 16px !important;
  font-weight: 400 !important;
  position: relative;
  margin-top: 3px;
}
.ownwalletInfo .ownMoney {
  display: flex;
  .m{
    font-family: 'Advent Pro', sans-serif;
    font-size: 18px !important;
    position: relative;
    margin-top: -2px;
  }
  .daimi{
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;

  }
}
  .prompt{
    font-size: 12px;
  }
  .endTime {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #08ebeb;
      margin-bottom: 10px;
      margin-top: 15px;
    }
  </style>
  <script>
  import swap_arrow from "@/assets/img/swap_arrow.svg";
  import burnbl from "@/assets/img/burnbl.svg";
 import bull2 from "@/assets/img/bull2.svg";
  import ProgressBar from "@/components/ProgressBar.vue";
  import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import FG1 from "@/assets/img/FG1.svg";
  import falocn from "@/assets/img/FalconLogoNew.svg";
  import daimni from "@/assets/img/daimini.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import Falocnn from "@/plugins/Falocnn.js";
  import Web3 from "web3";
  import axios from "axios";
  import sign from "@/plugins/sign.js";
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import big from "big.js";

  export default {
    data() {
      return {
        swap_arrow,
        swap_usdt,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typedeposit: 0,
        dai: dai,
        FG1: FG1,
        burnbl:burnbl,
        falocn: falocn,
        daimni: daimni,
        loadingimg: loadingimg,
        daiValue: "100",
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        bull2:bull2,
        usersdata: {},
        sitedata: {},
        addresscode: "",
        empowerbutton: true,

        Minimal: 0,
        isloading: false,
        falconAddresstype: true,
        isOpentype: false,
        flashAddresstype: true,
        returndatadata: "",
        promptvalue: "",
        honorLeave: "",
        tokenUsersinvestAmount:0,
        tokenvalue:"",
        mapTokenUsersdata:{},
        percentage:0,
        freezeTime:0,
        countdown:0,
        endTime: null,
        finishshow:false,
        value1:""
      };
    },
    components: {
      Marquee,
      //ProgressBar,
    },
    filters: {

fildecimals(e) {
  const multipliedNumber = e * 10000;
  const truncatedNumber = Math.trunc(multipliedNumber);
  const result = truncatedNumber / 10000;
  //    console.log(result);
  let value = parseFloat(result);
  return value;
},

fildecimals3(e) {
  console.log(e)
  const multipliedNumber = e * 100000;
  const truncatedNumber = Math.trunc(multipliedNumber);
  const result = truncatedNumber / 100000;
  //    console.log(result);

  let value = parseFloat(result);
  return value;
},
},
    async mounted() {

      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");

        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error(
              "Failed to obtain network information. Procedure:",
              error
            );
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        this.daiOwnMoney = parseFloat(
          await web3Utils.getDaiBalance(this.addresscode)
        );

        //  console.log(this.daiOwnMoney.toFixed(6));

        this.signtype();

      }
    },
    methods: {
      funlastcalltime() {
      const date = this.freezeTime


// 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
// localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        console.log('asdasdasdasd')
        // this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(date);
// setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(date)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }


      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign.getauthorization(this.addresscode).then((res) => {

          if (res.code == 0) {

            this.tokenUsers()


          }
          this.isloading = false;
        });
      },


      async mapTokenUsers() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .mapTokenUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.sitedata.USDT_swap.address
          )
          .then((res) => {
            console.log(res,'mapTokenUsers')
           this.mapTokenUsersdata=res.data
           this.freezeTime=res.data.freezeTime*1000
           this.funlastcalltime()
           let x = new big(res.data.extractedMapAmount*1);
          let y = new big(this.tokenUsersinvestAmount*1);
          if(res.data.extractedMapAmount==0){
            this.percentage=0
          }else{
            let result = x.div(y).mul(100);
// 使用 div 方法进行除法
this.percentage = result.toString()
          }

          });
      },
      // tokenUsers
    async tokenUsers() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.sitedata.USDT_swap.address
        )
        .then((res) => {
          console.log(res, 'tokenUsers')

          this.tokenUsersinvestAmount = res.data.investAmount
          this.mapTokenUsers();
        });
    },

    async recoveryMapUsers() {
      this.isloading = true;
      let _decimals = 6;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
      try {
        await contract.recoveryMapUsers(
          Address, //Contract address
          this.tokenvalue, //number
          this.addresscode,//current address
          _decimals,//float
          this.value1
        ).then((res) => {
          console.log(res);
          this.typeReceive = 0;
          if (res.code == 0) {
            this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: '映射成功'
            });
            this.mapTokenUsers();
          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: '失败'
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        console.log(error);
      }
    },

      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.daiOwnMoney) return "0.0000";

        const multipliedNumber = this.daiOwnMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);
        //    console.log(this.daiOwnMoney);
        let value = parseFloat(result);


        return value;
      },
      formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    },
  };
  </script>
