import Web3 from "web3";
import {ethers} from 'ethers';
import big from "big.js";
// DAI合约的通用ERC-20 ABI和地址
const DAI_ABI = [
  {
    constant: true,
    inputs: [{name: "_owner", type: "address"}],
    name: "balanceOf",
    outputs: [{name: "balance", type: "uint256"}],
    type: "function",
  },
];
const DAI_CONTRACT_ADDRESS = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";

const web3 = new Web3(window.ethereum || "https://polygon-rpc.com");

  
let isRequestingAccounts = false; // 全局变量，表示当前是否正在请求账户连接

async function connectWallet2() {
  console.log(isRequestingAccounts,'isRequestingAccounts')
  if (isRequestingAccounts) {
    console.warn("连接请求正在处理中，请稍后再试");
    return null;
  }

  try {
    isRequestingAccounts = true; // 设置标志，表示请求正在进行
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log(accounts, 'accountsaccountsaccountsaccounts');
    return accounts.length > 0 ? accounts[0] : null;
  } catch (error) {
    console.error("Error connecting wallet:", error);
    return null;
  } finally {
    console.log('恢复')
    isRequestingAccounts = false; // 重置标志
  }
}
async function connectWallet() {
  if (typeof window.ethereum !== 'undefined') {
    try {
      // 检查是否有挂起的请求
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (accounts.length > 0) {
        console.log('已连接的账户:', accounts);
        return accounts[0];
      } else {
        console.log('请在钱包中完成授权请求...');
        return null;
      }
    } catch (error) {
      console.error('连接钱包时出错:', error);
      return null;
    }
  } else {
    console.log('未检测到以太坊钱包');
    return null;
  }
}

async function getDaiBalance(address) {
  try {
    // const daiContract = new web3.eth.Contract(DAI_ABI, DAI_CONTRACT_ADDRESS);
    // const balanceWei = await daiContract.methods.balanceOf(address).call();
    // console.log(balanceWei,'balanceWeibalanceWeibalanceWei')
    // return web3.utils.fromWei(balanceWei, "ether");


    const provider = new ethers.providers.Web3Provider(window.ethereum);
    console.log(provider, '111111111111111111111111111')
    const contract = new ethers.Contract(DAI_CONTRACT_ADDRESS, DAI_ABI, provider);
    const balance = await contract.balanceOf(address)
    console.log(balance.toString(), 'getDaiBalancegetDaiBalancegetDaiBalance')
    var datas = big(balance.toString())
      .div(10 ** 18)
      .toFixed();
    return datas
  } catch (error) {
    console.error("Error fetching DAI balance:", error);
    return null;
  }
}

// 获取 MATIC 余额
async function getMaticBalance(accountAddress) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // 获取账户余额
    const balanceWei = await provider.getBalance(accountAddress);

    // 将余额从 Wei 转换为 MATIC
    const balanceMatic = ethers.utils.formatEther(balanceWei);

    console.log(`MATIC 余额：${balanceMatic}`);
    return balanceMatic
  } catch (error) {
    console.error('获取余额时发生错误:', error);
    return null;
  }
}

export default {
  connectWallet,
  connectWallet2,
  getDaiBalance,
  getMaticBalance
};
