<template>
  <div class="stakewait">
    <!-- notification start -->
    <!-- <div class="marquee">
      <Marquee :duration="15">{{ promptvalue }}</Marquee>

    </div> -->

    <!-- notification end -->
    <div class="coinInfomation">
      <div class="coinImg"><img :src="floans" class="dailogo"/></div>
      <div class="coinInfo">{{ $t("menu.Flashloan") }}</div>
    </div>

   

    <!-- <div class="infoContent">
      <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
      <div class="infoText">{{ $t("flashloans.tips2") }}</div>
    </div> -->

  
   <div class="ownwalletInfo2">
      <div class="ownTips2">{{$t("flashloans.YourBalance")}}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
       {{ getCountdata }}
      </div>
    </div>
   
    <Divider style="background: rgba(142, 200, 243, 0.5)"/> 

    <div class="infoContent m20">
      <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
      <div class="infoText">{{$t("flashloans.numer")}}</div>
    </div>

    <div class="slider" >     
        <van-slider  v-model="slidervalue" :step="1" :max="2" @change="onChange"
          bar-height="10px" active-color="#DFBEF3">
          <div slot="button" class="custom-button">           
            <span v-if="slidervalue==1">1</span>
            <span v-else-if="slidervalue==2">3</span>
            <span v-else>{{ slidervalue }}</span>
          </div>
        </van-slider>       
    </div>

    <Divider style="background: rgba(142, 200, 243, 0.5)"/> 

    <div class="ownwalletInfo">
      <div class="ownTips">{{$t("flashloans.AlreadyStaked")}}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        {{ amount | fildecimals }}<img class="daimi" :src="burnFalcon"/>
      </div>
    </div>

    <div class="ownwalletInfo">
      <div class="ownTips">{{$t("flashloans.team")}}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        {{ teamAmount | fildecimals }}<img class="daimi" :src="burnFalcon"/>
      </div>
    </div>
    <div class="ownwalletInfo">
      <div class="ownTips">{{$t("flashloans.WalletBalance")}}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        {{ formattedFalconbalance }}<img class="daimi" :src="burnFalcon"/>
      </div>
    </div>

    <!-- <div class="ownwalletInfo">
        <div class="ownTips">Matic:</div>
        <div class="ownMoney">
            {{ getMaticBalancevalue | fildecimals }}<img class="swap_falcon" :src="swap_matic" />
        </div>
    </div> -->
    <div class="inputInfo">
      <input v-model.number="daiValue" type="number" class="inputStyle" @input="exchangeallowance"/>
      <button @click="(daiValue = amount),exchangeallowance()" class="tbnStyle ripple">
          {{ $t("network.all") }}
      </button>
    </div>


    <!-- <div class="autoProcess">
        <div class="a1">
            <input type="checkbox" checked="checked">
        </div>
        <div class="a2">
            <span> {{ $t("menu.xNumber") }}: {{ 0 }} ，{{ $t("menu.xTips") }}</span>
        </div>
    </div> -->

    <div class="StakeAuthor">
     
      <div class="Author" v-if="Falconall == false">
        <!-- appoove button start-->
        <button :disabled="Falconall" class="StakeBtn ripple" @click="empowerfalcon" v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }} Falcon
        </button>
        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>
      <div class="Author" v-else-if="amount<=0">
        <!-- appoove button start-->
        <button  class="StakeBtn ripple" @click="deposit" >
          {{$t("flashloans.stake")}}
        </button>
       
      </div>
      <div class="Author" v-else>
        <!-- appoove button start-->
        <button  class="StakeBtn ripple" @click="withdraw" v-if="timershow==true">
          {{$t("flashloans.reStake")}}
        </button>
        <button disabled  class="StakeBtntype ripple" v-else>
          <img src="../assets/img/suo.svg" alt="">
          {{$t("flashloans.reStake")}}
        </button>
       
      </div>
      <!-- 当前合约用户是否存入 -->
      <!-- 不可点击 -->
      <div class="Stakeing" >
        <button :disabled="true" class="StakeBtn ripple">
          <!-- {{ $t("menu.Launch") }} -->
          {{ $t("flashloans.wait") }}
        </button>
      </div>
      <!-- 正常流程 -->
      <!-- <div class="Stakeing" v-else>
        <button v-if="empowerbutton == true && Falconall == true" class="StakeBtn ripple" @click="getindex">
          {{ $t("menu.Launch") }}
        </button>
        <button :disabled="depositDisabled" v-else class="StakeBtn ripple" @click="getindex">
          {{ $t("menu.Launch") }}
        </button>

      </div> -->
    </div>
    <div class="endTime" style="font-size: 16px;" v-if="timershow==false">
       {{ countdown }} END
      </div>

      <div class="ownwalletInfo">
      <div class="ownTips">{{$t("flashloans.bind")}}:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        <!-- {{ formattedFalconbalance }}<img class="daimi" :src="burnFalcon"/> -->
      </div>
    </div>
    <div class="inputInfo">
      <input v-model="textvalue" type="text" class="inputStyle" style="width: 100%;"/>
      <!-- <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
          {{ $t("network.all") }}
      </button> -->
    </div>
    <div class="StakeAuthor">
    
     
     <div class="Stakeing" >
       <button class="StakeBtn ripple" @click="bind()" v-if="referrer=='0x0000000000000000000000000000000000000000'">
        {{$t("flashloans.bind")}}
       </button>
       <button class="StakeBtntype ripple" disabled v-else>
        {{$t("flashloans.bind")}}
       </button>
     </div>
  
   </div>

    <div class="m20">
      <video class="btnvideo" src="https://dm.bull-developer.com/video/165.MP4" autoplay loop muted playsinline>
      {{$t("network.video")}}
      </video>
    </div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg"/>
    </div>
    <!--loading end-->
    <!--loading start-->
    <div v-if="isloading2" class="loading">
      <div class="tips">{{$t("flashloans.searing")}}</div>
      <img :src="loadingimg" class="loadimg"/>
    </div>
    <!--loading end-->
  </div>
</template>
<style scoped src="@/assets/css/FlashLoans.css"></style>
<style lang="less" scoped>
.m20{
  margin-top: 20px;
}
.btnimg img{
  margin-top: 20px !important;
}
.btnvideo{
  width:100%;
}
.ownwalletInfo2{
  margin-top: 30px;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  .ownTips2{
    flex: 1;
  }
}
.ownwalletInfo {  
  display: flex; 
  .ownTips{
    font-family: "Poppins", sans-serif;
    position: relative;
    margin-top: 2px;
    font-size: 16px !important;
  }
 
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import floans from "@/assets/img/floans.svg";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FKmini from "@/assets/img/FKmini.svg";
import burnFalcon from "@/assets/img/burnFalcon.svg";
import FG1 from "@/assets/img/FG1.svg";
import D1 from "@/assets/img/d1.svg";
import D2 from "@/assets/img/d2.svg";
import daimni from "@/assets/img/daimini.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import DAImini2 from "@/assets/img/DAImini2.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import loan from "@/plugins/loan.js";
import Falcon2js from "@/plugins/Falcon2js.js";
import flashjs from "@/plugins/flash.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
  data() {
    return {
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      floans:floans,
      burnFalcon:burnFalcon,
      FG1: FG1,
      D1: D1,
      D2: D2,
      daimni: daimni,
      swap_falcon: swap_falcon,
      DAImini2: DAImini2,
      swap_matic: swap_matic,
      loadingimg: loadingimg,
      FKmini: FKmini,
      daiValue: "100",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      isloading2: false,
      whiteAddresstype: false,
      isStarttype: false,
      flashAddresstype: true,
      userCallsvalue: 0,
      returndatadata: "",
      promptvalue: "",
      Falconall: false,
      Falconbalance: 0,
      getMaticBalancevalue: 0,
      endTime: null,
      countdown: '',
      timer: null,
      timershow: false,
      finishshow: false,
      slidervalue: 1,
      teamAmount:0,
      amount:0,
      startTime:0,
      textvalue:"",
      referrer:"",
      getCountdata:0,
      count:0
    };
  },
  components: {
    Marquee,
    //ProgressBar,
  },
  unmounted() {
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  filters: {

    fildecimals(e) {
      const multipliedNumber = e * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);
      return value;
    },
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({method: "eth_chainId"})
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({path: "/"});
            return;
          }
        })
        .catch((error) => {
          console.error("Failed to obtain network information. Procedure:", error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );
      console.log(this.daiOwnMoney)
      //  console.log(this.daiOwnMoney.toFixed(6));

      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.users();
            this.flashusers()
            this.exchangeallowance();
           
            this.minInvestAmount();
            this._whitesAddress()
            this.isStart()
            // this._flashAddress()
         
            this.balance()
            this.onMATIC()
          }
          this.isloading = false;
        });
    },
    funlastcalltime() {
      const date = this.startTime * 1000

      console.log(date, '22222222222222222222', this.startTime * 1000 * 24)
// 用法示例

      const storedEndTime = date + (30 * 24 * 60 * 60) * 1000;
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
// localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(storedEndTime);
// setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(storedEndTime)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }


      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    async onMATIC() {
      var that = this
      this.getMaticBalancevalue = parseFloat(
        await web3Utils.getMaticBalance(this.addresscode)
      );

      console.log(this.getMaticBalancevalue, 'getMaticBalancevalue')
    },
    async _whitesAddress() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        ._whitesAddress(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, '_whitesAddress')
          this.whiteAddresstype = res.data
        });
    },
    async isStart() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        .isStart(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, 'isStart')
          this.isStarttype = res.data
          console.log(this.isStarttype)
        });
    },
    async _flashAddress() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        ._flashAddress(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, '_flashAddress')
          this.flashAddresstype = res.data
          if (this.userCallsvalue) {
            this.promptvalue = this.$t("network.flashloansError")
          } else {
            this.promptvalue = this.$t("network.flashloansOnce")
          }

        });
    },
   
   
    async getdata() {
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        this.daiOwnMoney = parseFloat(
          await web3Utils.getDaiBalance(this.addresscode)
        );
        console.log(this.daiOwnMoney)
        //console.log(this.daiOwnMoney.toFixed(6));
        this.users();
      
        this.minInvestAmount();
        //this.exchangeallowance()
      }
    },
    validateAmount() {
      //console.log(this.daiValue)
      //console.log(this.Minimal)
      console.log(this.formattedDaiOwnMoney)
      if (this.daiValue * 1 < this.formattedDaiOwnMoney) {
        // this.isButtonDisabled = true;
        // this.depositDisabled = true;
      } else {
        // this.isButtonDisabled = false;
        // this.depositDisabled = false;
        this.daiValue = this.formattedDaiOwnMoney
        this.exchangeallowance();
      }
    },
    onChange(){
      this.getAmounts()
    },
    // Falcon余额
    async balance() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.Falcon_token.address;
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,
        _decimals
      ).then((result) => {
        console.log(result, "Falcon余额");
        this.Falconbalance = result.data

      });
    },
    // Authorization button
    empower() {
      this.isloading = true;  // open loading
      this.Approve();
    },
    // falcon授权
    empowerfalcon() {
      this.isloading = true;  // open loading
      this.Approve2();
    },
    // contract Approve
    async Approve() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.dai_token.address;
      spender = this.sitedata.falconv3_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton = true;
            this.validateAmount();
          } else {
            // this.typeApprove = 1;
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    async Approve2() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.Falcon_token.address;
      spender = this.sitedata.flash_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens2(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.Falconall = true;
            // this.validateAmount();
            
          } else {
            // this.typeApprove = 1;
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    // View the number of authorized contracts
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      spender = this.sitedata.flash_token.address;
    
      var Address2 = this.sitedata.Falcon_token.address;
      await Plugens.allowancetoken(
        Address2,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result.data, 'Falcon授权数量')
        if (result.data * 1 > this.daiValue) {
          this.Falconall = true;
        } else {
          this.Falconall = false;
        }

      });
    },
    // Contract deposit
    async deposit() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.flash_token.address;
      this.typedeposit = 1;
      var value=0
      if(this.slidervalue==1){
         value=1
      }else if(this.slidervalue==2){
         value=3
      }
      try {
        await flashjs
          .deposit(
            Address, //Contract address
            value, //quantity
            this.addresscode, //Current address
            _decimals //Fractional part
          )
          .then((res) => {
            // console.log(res);
            this.typedeposit = 0;
            this.isloading = false;
            if (res.code == 0) {
              this.typedeposit = 0;
              this.$Notice.success({
                title: this.$t("stake.StakeSuccessful")
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();
              this.flashusers()
            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("stake.StakeFailed")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          });
      } catch (error) {
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("stake.StakeFailed")
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },
    async withdraw() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.flash_token.address;
      var value=0
      if(this.slidervalue==1){
         value=1
      }else if(this.slidervalue==2){
         value=3
      }
      try {
        await flashjs
          .withdraw(
            Address, //Contract address
            value, //quantity
            this.addresscode, //Current address
            _decimals //Fractional part
          )
          .then((res) => {
            // console.log(res);
          
            this.isloading = false;
            if (res.code == 0) {
              
              this.$Notice.success({
                title: this.$t("stake.StakeSuccessful")
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();
              this.flashusers()
            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("stake.StakeFailed")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          });
      } catch (error) {
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("stake.StakeFailed")
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },
    async bind() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.flash_token.address;
     
      try {
        await flashjs
          .bind(
            Address, //Contract address
            this.textvalue, //quantity
            this.addresscode, //Current address
            _decimals //Fractional part
          )
          .then((res) => {
            // console.log(res);
          
            this.isloading = false;
            if (res.code == 0) {
              
              this.$Notice.success({
                title: this.$t("stake.StakeSuccessful")
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();
              this.flashusers()
            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("stake.StakeFailed")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          });
      } catch (error) {
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("stake.StakeFailed")
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },
   
   
    // Minimum total input
    async minInvestAmount() {
      // console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      // console.log("Minimum total input");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .minInvestAmount(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //      console.log(res, "Minimum total input");
          this.Minimal = res.data;
        });
    },
    // User information
    async users() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //console.log(res, "User information");
          this.usersdata = res.data;
          this.stakedMoney = res.data.investAmount * 1;
          //console.log(this.stakedMoney);
          //const total = 14000;
          //this.currentProgress = (this.stakedMoney / total) * 100;
        });
    },
    async flashusers() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.flash_token.address;
      await flashjs
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          console.log(res, "flashusers");
          this.teamAmount=res.data.teamAmount
          this.referrer=res.data.referrer
          if(this.referrer!='0x0000000000000000000000000000000000000000'){
            this.textvalue=this.referrer
          }
         
          this.amount=res.data.amount
          this.count=res.data.count
          this.startTime=res.data.startTime
          // this.startTime='1735993188'
          
          this.funlastcalltime()
          this.getCount()
          this.getAmounts()
        
        });
    },
    async getCount() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.flash_token.address;
      await flashjs
        .getCount(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          console.log(res, "getCount");
          this.getCountdata=res.data
        
        });
    },
    async getAmounts() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.flash_token.address;
      var value=0
      if(this.slidervalue==1){
         value=1
      }else if(this.slidervalue==2){
         value=3
      }
      await flashjs
        .getAmounts(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          value
        )
        .then((res) => {
          console.log(res, "getAmounts");
          this.daiValue=res.data
          
          
        
        });
    },
    // tb地址接口
    getindex() {
      this.isloading2 = true;  // open loading
      axios
        .get(this.sitedata.url.address + '/api/index/flash?address=' + this.addresscode + '&amount=' + this.daiValue, {})
        .then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.returndatadata = res.data.data
            this.stake()
          } else {
            this.$Notice.warning({
              title: res.data.msg,
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
          }
        })
    },

    // Stake button
    async stake() {

      var that = this
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      console.log(getGasPrice, 'getGasPricegetGasPricegetGasPrice')
      //  预估


      const estimatedGas = {
        from: that.addresscode, // 替换为发送者地址
        to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
        data: that.returndatadata, // 替换为转账金额
      }
      console.log(estimatedGas)
      // web3.eth.sendTransaction({
      //         from: that.addresscode, // 替换为发送者地址
      //         to: '0x905952f886282D90b308686c048f6F7F44495D86', // 替换为收款地址
      //         data: that.returndatadata, // 替换为转账金额
      //         gas: 41000,
      //         gasPrice: getGasPrice,
      //         // nonce: await web3.eth.getTransactionCount(that.addresscode), //
      //     })
      //     .then((res) => {
      //         console.log('sendTransaction:', res);
      //     })
      //     .catch((error) => {
      //         console.error('Error:', error);
      //     });
      web3.eth.estimateGas(estimatedGas)
        .then((res) => {
          console.log('Estimated Gas:', res);
          web3.eth.sendTransaction({
            from: that.addresscode, // replace sender address
            to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
            data: that.returndatadata, // 替换为转账金额
            gas: res,
            gasPrice: Math.round(getGasPriceto * 1.5),
          })
            .then((res) => {
              console.log('sendTransaction:', res);
              this.isloading2 = false;
              this.users();
              this.exchangeallowance();
           
              this.minInvestAmount();
              this._whitesAddress()
              this.isStart()
              // this._flashAddress()
              
              this.balance()
              this.onMATIC()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            })
            .catch((error) => {
              console.error('Error:', error);
              this.isloading2 = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            });
        })
        .catch((error) => {
          this.isloading2 = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          console.error('Error:', error);
        });


    },

    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("network.stake"),
        //desc: nodesc ? "" : this.$t("network.stake"),
      });
    },
  },
  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.0000";
      console.log(this.daiOwnMoney)
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);
      console.log(value)

      return value
    },
    formattedFalconbalance() {
      if (!this.Falconbalance) return "0.0000";
      console.log(this.Falconbalance)
      const multipliedNumber = this.Falconbalance * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      let value = parseFloat(result);
      console.log(value)

      return value
    },

    formattedDaiOwnMoney2() {
      if (!this.stakedMoney) return "0.0000";
      const multipliedNumber = this.stakedMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
  },
};
</script>
